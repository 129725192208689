import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

interface UserRequestParams {
  userId: string;
  email: string;
  name?: string;
}

interface ApiUrls {
  [key: string]: string;
}

const userApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/users',
  dev: 'https://api.wallscapes.ai/dev/user',
  prod: 'https://api.wallscapes.ai/prod/user',
};

const getUserApiUrl = () => {
  const environment = getEnvironment();
  return userApiUrls[environment];
};

export interface User {
  userId: string;
  email: string;
  name?: string;
  role?: string;
  createdDate?: Date;
  updatedDate?: Date;
}

const useUserApi = () => {
  const { getAccessTokenSilently, user } = useAuth0();

  const createUser = async (params: UserRequestParams): Promise<User> => {
    const endpointUrl = getUserApiUrl();
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(endpointUrl, params, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200 && response?.data?.status) {
        return response.data.data;
      } else {
        throw new Error('Unable to create user.');
      }
    } catch (error) {
      console.log('Error creating user:', JSON.stringify(error));
      throw new Error('Failed to create user');
    }
  };

  const getUsers = async (
    limit: number = 10,
    lastKey?: Record<string, any>
  ): Promise<{ items: Array<Object>; lastKey: Record<string, any> | null }> => {
    const params = new URLSearchParams({
      limit: limit.toString(),
      lastKey: lastKey ? JSON.stringify(lastKey) : '',
    });

    const endpointUrl = `${getUserApiUrl()}?${params.toString()}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (
        response?.status === 200 &&
        response?.data?.status === 'success' &&
        response?.data?.data
      ) {
        return response.data.data;
      } else {
        throw new Error(
          'No user data found in the response or status is not success.'
        );
      }
    } catch (error) {
      console.error('Error calling user API:', JSON.stringify(error));
      throw new Error('Failed to retrieve user data from the API');
    }
  };

  const getUser = async (userId: string): Promise<User> => {
    const endpointUrl = `${getUserApiUrl()}/${encodeURIComponent(userId)}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200 && response?.data?.data) {
        return response.data.data as User;
      } else {
        throw new Error(
          'No user data found in the response or status is not success.'
        );
      }
    } catch (error: any) {
      console.error('Error calling user API:', error?.message);
      throw new Error('Failed to retrieve user data from the API');
    }
  };

  const getOrCreateUser = async (): Promise<User> => {
    try {
      const auth0Id = user?.sub; // Use Auth0 `sub` claim as unique identifier
      const email = user?.email;

      if (!auth0Id || !email) {
        throw new Error('Authenticated user information is missing.');
      }

      // Try to fetch the user
      const existingUser = await getUser(auth0Id);
      return existingUser;
    } catch (error) {
      console.warn('User not found. Creating a new user...');
      // If user doesn't exist, create them
      const newUser = await createUser({
        userId: user?.sub as string,
        email: user?.email as string,
        name: user?.name,
      });
      return newUser;
    }
  };

  return { createUser, getUsers, getUser, getOrCreateUser };
};

export default useUserApi;
