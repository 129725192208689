import React, { useState } from 'react';
import { Button, Form, Input, Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ContactUs: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleCaptchaVerify = (token: string) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name || !message || (!email && !mobile)) {
      setErrorMessage('Please fill out all fields and provide either an email or mobile number.');
      return;
    }

    if (!captchaToken) {
      setErrorMessage('CAPTCHA validation failed. Please complete the CAPTCHA.');
      return;
    }

    setErrorMessage('');
    setLoadingState(true);

    try {
      // Simulate sending message
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoadingState(false);
      toggleModal();
    } catch (error) {
      setLoadingState(false);
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col md="6" className="text-center">
          <h1>Contact Us</h1>
          <p>We'd love to hear from you! Please fill out the form below and we'll get back to you as soon as possible.</p>

          {/* Contact Form */}
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Input
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Input
                  type="text"
                  placeholder="Order Number (Optional)"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Input
                  type="email"
                  placeholder="Your Email (Optional)"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Input
                  type="text"
                  placeholder="Your Mobile Number (Optional)"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Input
                  type="textarea"
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={5}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <HCaptcha
                  sitekey="YOUR_HCAPTCHA_SITE_KEY"
                  onVerify={handleCaptchaVerify}
                />
              </Col>
            </Row>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <Button className="w-100" type="submit" disabled={loadingState}>
              {loadingState ? 'Sending...' : 'Submit'}
            </Button>
          </Form>

          {/* Success Modal */}
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalBody className="text-center">
              <h2>Thank You!</h2>
              <p>Your message has been sent. We'll get back to you soon.</p>
              <Button color="secondary" onClick={toggleModal}>Close</Button>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
