import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Spinner } from 'reactstrap';
import useContentApi from '../../../managers/ContentManager';

interface PromptIdeasProps {
  onPromptSelect: (prompt: string) => void;
}

const PromptIdeas: React.FC<PromptIdeasProps> = ({ onPromptSelect }) => {
  const [promptIdeas, setPromptIdeas] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false); // State for loading status
  const { generatePromptIdeas } = useContentApi();
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchPromptIdeas = async () => {
      const sessionKey = 'fetchedPromptIdeas'; // Key for sessionStorage

      // Check if the data has already been fetched this session
      let ideas = JSON.parse(sessionStorage.getItem(sessionKey) || "{}");
      if (ideas?.length > 0) {
        console.log('Prompt ideas already fetched for this session.');
        setPromptIdeas(ideas || []);
        return;
      }

      setIsLoading(true); // Set loading to true
      try {
        const ideas = await generatePromptIdeas();
        setPromptIdeas(ideas || []);

        // Mark as fetched in sessionStorage
        sessionStorage.setItem(sessionKey, JSON.stringify(ideas));
      } catch (error) {
        console.error('Error fetching prompt ideas:', error);
      } finally {
        setIsLoading(false); // Set loading to false
      }
    };

    if (!hasFetched.current) {
      fetchPromptIdeas();
      hasFetched.current = true;
    }
  }, [generatePromptIdeas]);

  return (
    <>
      {isLoading ? (
        <div className="text-center mb-1 fs-6">
          <Spinner size="sm" color="primary" />
          Loading prompt ideas...
        </div>
      ) : (
        promptIdeas.length > 0 && (
          <div className="mb-4">
            <h4 className="text-center">Prompt Ideas</h4>
            <Row className="g-2 text-center">
              {promptIdeas.map((idea, index) => (
                <Col key={index} xs="12">
                  <Button
                    color="primary"
                    size="sm"
                    className="w-100"
                    onClick={() => onPromptSelect(idea)}
                    title={idea} // Tooltip for full prompt
                  >
                    {idea}
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
        )
      )}
    </>
  );
};

export default PromptIdeas;
