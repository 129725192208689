// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Custom from './views/products/Custom';
import Products from './views/products/Products';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './views/auth/LoginUniversal';
import LoginCallback from './auth/Auth0Callback';
import { Navigation } from './components/Navigation';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import Success from './views/payment/Success';
import Cancel from './views/payment/Cancel';
import OrderDetail from './views/order/OrderDetail';
import OrderError from './views/order/OrderError';
import Account from './views/account/Account';
import './styles/global.css';
import Footer from './components/Footer';
import Privacy from './views/policies/Privacy';
import Terms from './views/policies/Terms';
import Shipping from './views/policies/Shipping';
import Returns from './views/policies/Returns';
import About from './views/about/About';
import IntellectualProperty from './views/policies/IntellectualProperty';
import ContactUs from './views/contact/ContactUs';

const App: React.FC = () => {
  return (
    <ShoppingCartProvider>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navigation />
          <main style={{ flex: 1, padding: '16px' }}>
            <Routes>
              <Route path="/" element={<ProtectedRoute />}>
                <Route path="" element={<Home />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/callback" element={<LoginCallback />} />
              <Route path="/products" element={<ProtectedRoute />}>
                <Route path="" element={<Products />} />
                <Route path="custom" element={<Custom />} />
              </Route>
              <Route path="/payment">
                <Route path="success" element={<Success />} />
                <Route path="cancel" element={<Cancel />} />
              </Route>
              <Route path="/account" element={<ProtectedRoute />}>
                <Route path="" element={<Account />} />
              </Route>
              <Route path="/order">
                <Route path="error" element={<OrderError />} />
                <Route path=":orderId" element={<OrderDetail />} />
              </Route>
              <Route path="/about" element={<About />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/returns" element={<Returns />} />
              <Route path="/intellectual-property" element={<IntellectualProperty />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ShoppingCartProvider>
  );
};

export default App;
