import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

interface ContentRequestParams {
  userId: string;
  action: string;
  prompt?: string;
  vendor?: string;
}

interface ApiUrls {
  [key: string]: string;
}

const contentApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/content',
  dev: 'https://api.wallscapes.ai/dev/content',
  prod: 'https://api.wallscapes.ai/prod/content',
};

const getContentApiUrl = () => {
  const environment = getEnvironment();
  return contentApiUrls[environment];
};

const useContentApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Generate a name based on a prompt.
   * @param {ContentRequestParams} params - Parameters for generating a name.
   * @returns {Promise<any>} - Generated name.
   */
  const generateName = async (params: ContentRequestParams): Promise<any> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(getContentApiUrl(), params, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200) {
        return response.data.data;
      } else {
        throw new Error('Failed to generate name. Response status not successful.');
      }
    } catch (error) {
      console.error('Error generating name:', error);
      throw new Error('Failed to generate name.');
    }
  };

  /**
   * Retrieve prompt ideas.
   * @returns {Promise<string[]>} - List of prompt ideas.
   */
  const generatePromptIdeas = async (): Promise<string[]> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(getContentApiUrl(), {
        params: {
          operation: 'generatePromptIdeas',
          userId: 0,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200) {
        const content = response.data?.data?.content || '';
        // Parse the content string into an array of strings
        const ideas = content
          .split('|') // Split on new lines
          .map((idea: string) => idea.trim()) // Trim whitespace
          .filter(Boolean); // Remove empty strings
        return ideas;
      } else {
        console.error(`Error retrieving prompt ideas (status=${response?.status}):`);
        throw new Error('Failed to retrieve prompt ideas. Response status not successful.');
      }
    } catch (error) {
      console.error('Error retrieving prompt ideas:', error);
      throw new Error('Failed to retrieve prompt ideas.');
    }
  };
  return { generateName, generatePromptIdeas };
};

export default useContentApi;
