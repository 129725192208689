import React from 'react';
import { Button, Container, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ProductDetailProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  productImgUrl: string;
  title: string;
  description: string;
  price: string;
  onAddToCart: () => void;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  isModalOpen,
  toggleModal,
  productImgUrl,
  title,
  description,
  price,
  onAddToCart,
}) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} fullscreen>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody className="d-flex flex-column overflow-auto" style={{ maxHeight: 'calc(100vh - 130px)' }}>
        <Container>
          <Row>
            <Col xs={8} className="text-center mb-3">
              <img
                src={productImgUrl}
                alt="Generated Product"
                className="ws-img-clickable img-fluid"
                onClick={toggleModal}
                style={{ maxWidth: '100%', objectFit: 'contain' }}
              />
            </Col>
            <Col xs={4}>
              {title}
              <p>{description}</p>
              <span className="fw-bold">{price}</span>
              <Button color="primary" className="mt-3" onClick={onAddToCart}>
                Add to Cart
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductDetail;
